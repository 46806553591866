// // Join.js
// import React, { useRef } from 'react';
// import './Join.css'
// import emailjs from '@emailjs/browser'

// const Join = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();
//     emailjs
//       .sendForm('service_1k7jv9e', 'template_f83hpsy', form.current, 'w7gqE0xZzu32Rgu-f')
//       .then(
//         () => {
//           console.log('SUCCESS!');
//         },
//         (error) => {
//           console.log('FAILED...', error.text);
//         },
//       );
//   };

//   return (
//     <div className="Join" id="join-us">
//       <div className='left-j'>
//         <hr />
//         <div>
//           <span className='stroke-text'>READY TO</span>
//           <span>LEVEL UP</span>
//         </div>

//         <div>
//           <span>YOUR BODY</span>
//           <span className='stroke-text'>WITH US?</span>
//         </div>
//       </div>

//       <div className='right-j'>
//         <form ref={form} className="email-container" onSubmit={sendEmail}>
//           <input type="email" name='user_email' placeholder='Enter your email address' />
//           <button className='btn btn-j'>Join Now</button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default Join;
import React, { useRef, useState } from 'react';
import './Join.css';

const Join = () => {
  const form = useRef();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    plan: 'Basic',
    cardio: 'yes',
    age: '',
    weight: '',
    height: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/join', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      });
      const result = await response.json();
      if (result.success) {
        alert('Successfully joined!');
      } else {
        alert('Submission failed.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error submitting the form.');
    }
  };

  return (
    <div className="Join" id="join-us">
      <form ref={form} className="join-form" onSubmit={handleSubmit}>
        <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
        <input type="tel" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleChange} required />
        
        <select name="plan" value={formData.plan} onChange={handleChange} required>
          <option value="Basic">Basic</option>
          <option value="Premium">Premium</option>
        </select>
        
        <div>
          <label>
            <input type="radio" name="cardio" value="yes" checked={formData.cardio === 'yes'} onChange={handleChange} /> Yes
          </label>
          <label>
            <input type="radio" name="cardio" value="no" checked={formData.cardio === 'no'} onChange={handleChange} /> No
          </label>
        </div>
        
        <input type="number" name="age" placeholder="Age" value={formData.age} onChange={handleChange} required />
        <input type="number" name="weight" placeholder="Weight (kg)" value={formData.weight} onChange={handleChange} required />
        <input type="number" name="height" placeholder="Height (cm)" value={formData.height} onChange={handleChange} required />
        
        <button type="submit" className="btn btn-join">Join Now</button>
      </form>
    </div>
  );
};

export default Join;
